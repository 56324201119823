/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import moment from 'moment';
import { shelfIOSPeriods, shelfPeriods, shelfSkanModeledCohortPeriods } from 'reducers/reportsConfig/shelfPeriods';
import { reportDataToChartData } from 'utils/charts';
import {
    DEFAULT_APP_FILTER,
    DEFAULT_SOURCE_FILTER,
    defaultLastFilter,
    getDrilldownDimensions,
    listContains,
    reportTypes,
    transparencyReportTypes,
} from 'utils/reports';
import { ACCOUNT_TYPES, COHORT_PERIODS_THRESHOLD, CUSTOMER_TIERS } from 'utils/consts';
import {
    getGridFilteredData,
    getTableDataWithTransparency,
    isFacebookCensored,
    reportDataToTableData,
} from 'utils/grids';
import { showCohortEvents, showConversionEvents } from 'customEvents/utils';
import { PUBLISHER_ESTIMATED_INDICATION } from 'utils/reportsConsts';
import { COMPARE_MODE, REGULAR_MODE } from 'components/pages/reports/consts';
import { NETWORKS_SPECIAL_ORIGINAL_DISPLAY_NAME_MAPPING } from 'utils/adNetworks';
import { getAccountType, getFields, getFieldsForType, getFilters } from './fields';
import { getAdminModeEnabled, getUserData, isUserAdNetwork } from './user';
import fieldVisibilityRules from './reportsConfig/fieldVisibilityRules';
import reportsConfig from './reportsConfig';
import { API_VERSIONS } from '../customDashboards/utils';

const arrangeFilters = array => {
    const filtered_array = array.filter(element => !element.tagDivider);

    const dimensions = [];
    const custom_dimensions = [];
    const creative_tags = [];

    // throw the elements into buckets
    filtered_array.forEach(element => {
        let bucket = dimensions;

        // IMPORTANT: the order of the if's here is critical !!!
        // because a tag group is also a dynamic dimension
        if (element.isTagGroup) {
            bucket = creative_tags;
        } else if (element.dynamic_dimension) {
            bucket = custom_dimensions;
        }

        bucket.push(element);
    });

    // prepare the headers
    const dimensions_headers = {
        creative_visible: true,
        display_name: 'STATIC.DIMENSIONS',
        divider: true,
        tagDivider: true,
        name: 'dimensions_divider',
    };

    const custom_dimensions_header = {
        creative_visible: true,
        display_name: 'STATIC.CUSTOM_DIMENSIONS',
        divider: true,
        tagDivider: true,
        name: 'custom_dimensions_divider',
    };

    const creative_tags_header = {
        creative_visible: true,
        display_name: 'STATIC.PAGE_HEADERS.CREATIVE_TAGS',
        divider: true,
        tagDivider: true,
        name: 'creative_tags_divider',
    };

    // prepare the final dimension list
    let final_dimensions = [];

    if (dimensions.length > 0) {
        final_dimensions.push(dimensions_headers);
        const sorted_dimensions = [...dimensions];
        sorted_dimensions.sort((a, b) => a.display_name.localeCompare(b.display_name));
        final_dimensions = final_dimensions.concat(sorted_dimensions);
    }

    if (custom_dimensions.length > 0) {
        final_dimensions.push(custom_dimensions_header);
        const sorted_custom_dimensions = [...custom_dimensions];
        sorted_custom_dimensions.sort((a, b) => a.display_name.localeCompare(b.display_name));
        final_dimensions = final_dimensions.concat(sorted_custom_dimensions);
    }

    if (creative_tags.length > 0) {
        final_dimensions.push(creative_tags_header);
        const sorted_creative_tags = [...creative_tags];
        sorted_creative_tags.sort((a, b) => a.display_name.localeCompare(b.display_name));
        final_dimensions = final_dimensions.concat(sorted_creative_tags);
    }

    // splice the source array
    array.splice(0, array.length);
    array.push(...final_dimensions);
};

const basicSelectors = [
    'selectedFields',
    'filters',
    'shelf',
    'header',
    'showFieldsSelection',
    'showSpinner',
    'showHeader',
    'selectedTab',
    'dates',
    'fieldWarnings',
    'fieldShowDownloadReport',
    'showDownloadReportDatesThreshold',
    'chartData',
    'reportData',
    'chart',
    'table',
    'anonymousId',
    'anonymousList',
    'reportWarning',
    'bookmark',
    'selectedGoal',
    'mode',
    'transparencyData',
    'queryRunning',
    'validationError',
    'unenriched',
    'admonAlignment',
    'showRedownloadsDimensionInReport',
    'showValidatedDimensionInReport',
    'shouldDrilldown',
    'showTransparencyPopover',
    'pivotTable',
    'dimensionDrilldownCellId',
    'postProcessFiltersUI',
    'isSlimMode',
    'isClearSelectionOn',
    'disableReportState',
    'openFieldsAvailability',
    'fieldsAvailabilityFieldName',
    'selectedSources',
    'showPopupDialog',
    'reportFormHasChanged',
    'isEditShelfOpen',
];

const getConfigForType = type => {
    return reportsConfig.find(conf => {
        return conf.type === type;
    });
};

// initializes the basic selectors
reportsConfig.forEach(reportType => {
    basicSelectors.forEach(basic => {
        if (!reportType.selectors) {
            reportType.selectors = {};
        }
        reportType.selectors[basic] = state => {
            return state[reportType.type][basic];
        };
    });
});

export const getReportCheckedFields = (type, reportType) => {
    let reportTypeObj = reportType;
    if (typeof reportType === 'string') {
        reportTypeObj = getConfigForType(reportType);
    }
    return createSelector(
        [
            getFieldsForType(type),
            reportTypeObj.selectors.selectedFields,
            reportTypeObj.selectors.isSlimMode,
            getAdminModeEnabled,
            getUserData,
        ],
        (fields, selectedFields, isSlimMode, adminModeEnabled, userData) => {
            const isStandardAnalytics = userData.organization_tier === CUSTOMER_TIERS.STANDARD_ANALYTICS;
            const cohortMetricsStandardAnalyticsTooltip = 'To learn more about enabling Cohort Metrics contact us';
            if (fields == null) {
                fields = [];
            }
            if (isStandardAnalytics) {
                selectedFields.cohortMetrics = [];
            }
            return fields
                .filter(field => reportTypeObj.visibleRule(field, adminModeEnabled, userData, isSlimMode))
                .map(field => ({
                    ...field,
                    tooltip:
                        field.actual_type === 'cohortMetrics' && isStandardAnalytics
                            ? cohortMetricsStandardAnalyticsTooltip
                            : field.tooltip,
                    checked:
                        selectedFields[field.actual_type] && selectedFields[field.actual_type].includes(field.name),
                }));
        }
    );
};

const getReportDates = reportType => {
    let reportTypeObj = reportType;
    if (typeof reportType === 'string') {
        reportTypeObj = getConfigForType(reportType);
    }
    const datesSelector = reportTypeObj.selectors.dates;
    return createSelector([getFieldsForType('dates'), datesSelector], (fieldDates, reportDates) => {
        if (!reportDates.end_date || !reportDates.start_date) {
            return fieldDates;
        }
        return reportDates;
    });
};

const getChartFormattedData = reportType => {
    return createSelector([reportType.selectors.chartData, getUserData], (chartData, userData) => {
        if (!chartData || !Object.keys(chartData).length) {
            return { metrics: [], dimensions: {}, orgOptions: {} };
        }

        if (chartData.results) {
            chartData.results = chartData.results
                .map(chartDataRow => (isFacebookCensored(chartDataRow) ? null : chartDataRow))
                .filter(chartDataRow => chartDataRow !== null);
        }

        const normalizedChartData = reportDataToChartData(chartData);
        normalizedChartData.orgOptions = {
            metricsLimit: 3,
        };
        // if chart_metrics_display exits in user data, map the chart type to the relevant metric
        if (userData) {
            if (userData.chart_metrics_display) {
                try {
                    const parsedChartMetricsDisplay = JSON.parse(userData.chart_metrics_display);
                    for (const metric of normalizedChartData.metrics) {
                        metric.chart_type = parsedChartMetricsDisplay[metric.name] || 'line';
                    }
                } catch (e) {}
            }
            normalizedChartData.orgOptions = {
                metricsLimit: userData.chart_metrics_limit || normalizedChartData.orgOptions.metricsLimit,
            };
        }
        return normalizedChartData;
    });
};

const getTableFormattedData = reportType => {
    let reportTypeObj = reportType;
    if (typeof reportType === 'string') {
        reportTypeObj = getConfigForType(reportType);
    }
    const reportDataSelector = reportTypeObj.selectors.reportData;
    const tableSelector = reportTypeObj.selectors.table;
    return createSelector(
        [
            reportDataSelector,
            tableSelector,
            reportTypeObj.selectors.shouldDrilldown,
            getUserData,
            reportTypeObj.selectors.postProcessFiltersUI,
            isUserAdNetwork,
        ],
        (reportData, table, shouldDrilldown, userData, postProcessFiltersUI, isAdNetwork) => {
            let mode = REGULAR_MODE;
            let showConfidenceInterval = false;
            if (table && table.request) {
                mode = table.request.compare ? COMPARE_MODE : mode;
                mode = table.request.is_goals ? 'goal' : mode;
                showConfidenceInterval =
                    table.request.confidence_interval_flag && table.request.confidence_interval_flag.includes('true');
            }
            const showDimensionDrilldownIcon = reportTypeObj.type === reportTypes.mainReport && mode !== 'goal';

            return {
                ...(reportData
                    ? reportDataToTableData(
                          reportType.type,
                          reportData,
                          mode,
                          shouldDrilldown,
                          table.filteredTotal,
                          showDimensionDrilldownIcon,
                          table.columnOrder || table.column_order,
                          postProcessFiltersUI,
                          showConfidenceInterval,
                          null,
                          reportType
                      )
                    : {}),
                reportId: reportData ? reportData.report_id : null,
                cacheTimestamp:
                    reportData && reportData.cache_timestamp
                        ? moment(reportData.cache_timestamp * 1000).format('lll:s')
                        : 'NOW',
                attributionTimezone:
                    userData && userData.impersonated_org_attribution_timezone
                        ? userData.impersonated_org_attribution_timezone
                        : userData.attribution_timezone,
                customer:
                    isAdNetwork && userData && userData.impersonated_org_company_display_name
                        ? userData.impersonated_org_company_display_name
                        : null,
                tiAttributionMigrationDate: userData && userData.ti_attribution_migration_date,
                dpouMigrationDates: userData && userData.dpou_migration_dates,
                snowflakeMigrationTime: userData && userData.snowflake_migration_time,
                useCalendricCohort: userData && userData.use_calendric_cohort,
            };
        }
    );
};

const getTransparencyData = reportType => {
    return createSelector(
        [reportType.selectors.tableDataFormatted, reportType.selectors.transparencyData],
        (tableData, transparencyData) => {
            return transparencyData &&
                tableData.columnDefs &&
                tableData.columnDefs.find(field => field.field === 'source')
                ? getTableDataWithTransparency(tableData, transparencyData, reportType.type)
                : tableData || {};
        }
    );
};

const getShelfShown = reportType => {
    let reportTypeObj = reportType;

    if (typeof reportType === 'string') {
        reportTypeObj = getConfigForType(reportType);
    }

    return createSelector([reportTypeObj.selectors.showFieldsSelection], showFieldsSelection => showFieldsSelection);
};

const getPeriods = (periods, reportType) => {
    return periods.filter(period => fieldVisibilityRules[reportType](period));
};

function updatePeriodsViewConfig(periodsItem, periods, reportType) {
    if (periodsItem) {
        // In case there are more cohort periods than the threshold, show them as tags.
        if (getPeriods(periods, reportType.type).length > COHORT_PERIODS_THRESHOLD) {
            periodsItem.group.type = 'tag';
            periodsItem.group.data.placeholder = 'STATIC.PLACEHOLDERS.SELECT_PERIOD';
        } else {
            periodsItem.group.type = 'multi';
            delete periodsItem.group.data.placeholder;
        }
    }
}

const getShelfFormatted = reportType => {
    return createSelector(
        [
            reportType.selectors.selectedTab,
            reportType.selectors.shelf,
            getFieldsForType('periods'),
            getFieldsForType('skanModeledCohortPeriods'),
            getFieldsForType('unifiedCohortPeriods'),
            isUserAdNetwork,
        ],
        (selectedTab = 'regular', shelf, periods, skanPeriods, combinedPeriods, isAdnetwork) => {
            let ret = shelf;
            if (typeof shelf === 'object' && !(shelf instanceof Array)) {
                // if the user is of partner org, we need to show different shelf options
                if (isAdnetwork && selectedTab === 'regular' && shelf.partner) {
                    ret = shelf.partner;
                } else {
                    ret = shelf[selectedTab];
                }
            }

            const periodsItem = ret.find(item => item.type === shelfPeriods.type);
            const skanPeriodsItem = ret.find(item => item.type === shelfSkanModeledCohortPeriods.type);
            const unifiedCohortPeriodsItem = ret.find(item => item.type === shelfIOSPeriods.type);

            updatePeriodsViewConfig(periodsItem, periods, reportType);
            updatePeriodsViewConfig(skanPeriodsItem, skanPeriods, reportType);
            updatePeriodsViewConfig(unifiedCohortPeriodsItem, combinedPeriods, reportType);

            return ret;
        }
    );
};

// A selector to format the fields for an api query
const formattedFilters = reportType =>
    createSelector([reportType.selectors.filters, getFilters], (storeFilters, fieldsFilters) =>
        storeFilters
            .filter(filter => filter.field.name !== 'default')
            .map(filter => {
                const fFilter = fieldsFilters.find(ff => ff.name === filter.field);
                return {
                    dimension: filter.field,
                    operator: filter.operator,
                    values: filter.tags.map(tag => {
                        const filterObj = fFilter && fFilter.values.find(value => value.display_name === tag);
                        return filterObj ? filterObj.name : tag;
                    }),
                    options: { no_lowercase: true },
                };
            })
    );

const getShowReportMeta = reportType =>
    createSelector([getAdminModeEnabled], adminModeEnabled => {
        return adminModeEnabled;
    });

// initialize the advanced selectors
reportsConfig.forEach(reportType => {
    reportType.fields.forEach(field => {
        reportType.selectors[field] = getReportCheckedFields(field, reportType);
    });
    reportType.selectors.reportDates = getReportDates(reportType);
    reportType.selectors.tableDataFormatted = getTableFormattedData(reportType);
    if (transparencyReportTypes.includes(reportType.type)) {
        reportType.selectors.transparencyDataFormatted = getTransparencyData(reportType);
    }
    reportType.selectors.chartDataFormatted = getChartFormattedData(reportType);
    reportType.selectors.shelfFormatted = getShelfFormatted(reportType);
    reportType.selectors.formattedFilters = formattedFilters(reportType);
    reportType.selectors.showReportMeta = getShowReportMeta(reportType);
});

const getAvailableDimensionsForDrilldown = () => {
    return createSelector(
        [getConfigForType(reportTypes.mainReport).selectors.reportData, getUserData],
        (reportData, userData) => {
            const { metadata = {} } = reportData;
            const isStandardAnalytics = userData.organization_tier === CUSTOMER_TIERS.STANDARD_ANALYTICS;

            const availableDrilldownDimensions = getDrilldownDimensions(metadata).filter(({ name, values = [] }) => {
                return !(metadata[name] || values.find(value => metadata[value]));
            });

            if (isStandardAnalytics) {
                return availableDrilldownDimensions.filter(dimension => dimension.standardAnalyticsVisible);
            }

            return availableDrilldownDimensions;
        }
    );
};

const getAvailableFiltersForType = type => {
    const reportType = reportsConfig.find(rType => {
        return type === rType.type;
    });
    return createSelector(
        [
            getFilters,
            reportType.selectors.filters,
            getFieldsForType('tagGroups'),
            getAdminModeEnabled,
            getUserData,
            reportType.selectors.isSlimMode,
        ],
        (filters, reportFilters, tagGroups, adminModeEnabled, userData, isSlimMode) => {
            const filteredFilters = filters.filter(filter => {
                filter.isTagGroup = tagGroups.some(group => {
                    return `${group.tag_group_id}` === filter.name;
                });

                return (
                    reportType.visibleRule(filter, adminModeEnabled, userData, isSlimMode) &&
                    !reportFilters.find(cFilter => {
                        return cFilter.field === filter.name;
                    })
                );
            });
            arrangeFilters(filteredFilters, tagGroups);
            return filteredFilters;
        }
    );
};

const getCalcFilterForType = type => {
    const reportType = reportsConfig.find(rType => {
        return type === rType.type;
    });
    return createSelector(
        [reportType.selectors.filters, getFilters, getFieldsForType('filterOperators'), getAccountType],
        (reportFilters, fieldsFilters, filterOperators, accountType) => {
            const isStandardAnalytics = window.organization_tier === CUSTOMER_TIERS.STANDARD_ANALYTICS;
            const isAdmonReport = type === 'adMonetization';
            const isPartner = accountType === ACCOUNT_TYPES.PARTNER;

            // When there is an exception with the all_fields route, this might happen.
            if (!filterOperators) {
                return null;
            }

            return reportFilters
                .filter(
                    cFilter =>
                        !(isStandardAnalytics && cFilter.field === DEFAULT_APP_FILTER.field) &&
                        !(isAdmonReport && cFilter.field === DEFAULT_SOURCE_FILTER.field) &&
                        !(isPartner && cFilter.field === DEFAULT_SOURCE_FILTER.field)
                )
                .map(cFilter => {
                    const ret = { ...defaultLastFilter };
                    if (
                        fieldsFilters &&
                        cFilter.field !== defaultLastFilter.field.name &&
                        cFilter.field !== defaultLastFilter.field
                    ) {
                        ret.field = fieldsFilters.find(
                            fFilter => fFilter.name === cFilter.field || fFilter.name === cFilter.field.name
                        );
                    }
                    ret.operator = filterOperators.find(operator => operator.name === cFilter.operator);
                    if (cFilter.tags && cFilter.tags.length && ret.field) {
                        ret.tags = cFilter.tags
                            .map(tag =>
                                ret.field.values.find(value => value.display_name === tag || value.name === tag)
                            )
                            .filter(tag => tag !== undefined);
                    }
                    return ret;
                });
        }
    );
};

const shouldShowDownloadReport = (reportType, type) => {
    const isDimensions = type === 'dimensions';
    return createSelector(
        [
            getConfigForType(reportType).selectors[type],
            getConfigForType(reportType).selectors.fieldShowDownloadReport,
            getConfigForType(reportType).selectors.showDownloadReportDatesThreshold,
            getReportDates(reportType),
        ],
        (fields, fieldShowDownloadReport, showDownloadReportDatesThreshold, reportDates) => {
            if (
                isDimensions &&
                moment(reportDates.end_date).diff(reportDates.start_date, 'day') > showDownloadReportDatesThreshold
            ) {
                return true;
            }
            if (Array.isArray(fields)) {
                for (const field of fields) {
                    if (field.checked && fieldShowDownloadReport[field.name]) {
                        return true;
                    }
                }
            }
            return false;
        }
    );
};

const getWarningForType = (reportType, type) => {
    return createSelector(
        [getConfigForType(reportType).selectors[type], getConfigForType(reportType).selectors.fieldWarnings],
        (fields, fieldWarnings) => {
            if (Array.isArray(fields)) {
                return [
                    ...new Set(
                        fields
                            .filter(field => field.checked && fieldWarnings[field.name])
                            .map(field => fieldWarnings[field.name].msg)
                    ),
                ];
            } else {
                // get field warning is not implemented for non-fieldGroup fields (the only case where fields will be an array)
                // It should be simple to add, though
                return [];
            }
        }
    );
};

function _buildValidPermutations(request, chartRefRows, chart) {
    request.valid_key_list_for_charts = [];
    request.valid_key_name_list_for_charts = [];

    let isKeyNameListExists = false;
    chartRefRows.forEach(row => {
        const permutation = [];
        request.permutation_keys.forEach(field => {
            permutation.push(encodeURIComponent(row[field.name]));
            if (!isKeyNameListExists) {
                request.valid_key_name_list_for_charts.push(field.name);
            }
        });
        if (!listContains(request.valid_key_list_for_charts, permutation)) {
            request.valid_key_list_for_charts.push(permutation);
        }
        isKeyNameListExists = true;
    });
    request.valid_key_list_for_charts = request.valid_key_list_for_charts.slice(0, chart.lines || 3);
}

const _addChartData = (request, reportData, fieldsFilters, chart, filteredRows) => {
    // handle case where table is empty, we want that filters will be empty and therefore the request won't be sent
    const { metadata = {}, results = [] } = reportData || {};
    const chartRefRows = filteredRows || results;
    if (reportData === null || !chartRefRows.length) request.filters = [];

    request.permutation_keys = [];
    request.chart = true;

    if (request.time_breakdown.includes('all') || request.time_breakdown.length === 0) {
        request.time_breakdown = ['day'];
    }

    // Arrange proper filters
    Object.keys(metadata).forEach(fieldName => {
        const field = metadata[fieldName];

        if (
            field.type === 'dimension' &&
            !['date_field', 'skan_postback_date', 'estimated_install_date'].includes(field.name)
        ) {
            request.permutation_keys.push(field);
        }

        const filterField = fieldsFilters.find(filter => filter.name === field.name);

        if (filterField) {
            const filter = {
                dimension: filterField.name,
                operator: 'in',
                values: [],
                options: { no_lowercase: true },
            };
            chartRefRows.forEach(row => {
                const filterValue = filterField.values.find(obj => {
                    let fieldDisplayName = obj.display_name;

                    if (field.name === 'source') {
                        fieldDisplayName = NETWORKS_SPECIAL_ORIGINAL_DISPLAY_NAME_MAPPING[obj.name] || fieldDisplayName;
                    }

                    return fieldDisplayName === row[field.name];
                });

                if (filterValue && filter.values.indexOf(filterValue.name) === -1) {
                    filter.values.push(filterValue.name);
                }
            });

            // Check if filter was already selected, if yes replace it with the proper one.
            const existingFilterIndex = request.filters.findIndex(
                cFilter => cFilter.dimension === filter.dimension && cFilter.operator === filter.operator
            );

            if (filter.values.length > 0) {
                if (existingFilterIndex > -1) {
                    request.filters.splice(existingFilterIndex, 1);
                }

                request.filters.push(filter);
            }
        }
    });

    request.permutation_keys.sort((a, b) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
    });

    _buildValidPermutations(request, chartRefRows, chart);
};

// This selector filters selectedFields by visibility, meaning if a field was selected and is no longer visible it won't be returned.
// This is for guarding cases of fields selected by default but are not visible.
const visibleSelectedFields = reportType => {
    const reportObject = getConfigForType(reportType);
    return createSelector(
        [
            reportObject.selectors.selectedFields,
            getAdminModeEnabled,
            getFields,
            getUserData,
            reportObject.selectors.isSlimMode,
        ],
        (selectedFields, adminModeEnabled, fields, userData, isSlimMode) => {
            const ret = {};
            Object.keys(selectedFields).forEach(fieldType => {
                ret[fieldType] = selectedFields[fieldType].filter(sf => {
                    const fieldObj = fields[fieldType].find(f => f.name === sf);
                    return fieldObj && reportObject.visibleRule(fieldObj, adminModeEnabled, userData, isSlimMode);
                });
            });
            return ret;
        }
    );
};

const getQueryDataForType = (reportType, queryType) => {
    // TODO: Move this code to class report handler

    const reportConfig = getConfigForType(reportType);

    return createSelector(
        [
            visibleSelectedFields(reportType),
            reportConfig.selectors.selectedFields,
            reportConfig.selectors.formattedFilters,
            reportConfig.selectors.dates,
            reportConfig.selectors.reportData,
            reportConfig.selectors.chart,
            getFilters,
            reportConfig.selectors.selectedGoal,
            reportConfig.selectors.selectedTab,
            reportConfig.selectors.mode,
            getFieldsForType('goals'),
            reportConfig.selectors.unenriched,
            reportConfig.selectors.admonAlignment,
            reportConfig.selectors.showRedownloadsDimensionInReport,
            reportConfig.selectors.showValidatedDimensionInReport,
            reportConfig.selectors.bookmark,
            getUserData,
            reportConfig.selectors.pivotTable,
            reportConfig.selectors.isSlimMode,
        ],
        (
            visibleSelectedFields,
            selectedFields,
            filters,
            dates,
            reportData,
            chart,
            fieldsFilters,
            selectedGoal,
            selectedTab,
            selectedMode,
            goals,
            unenriched,
            admonAlignment,
            showRedownloadsDimensionInReport,
            showValidatedDimensionInReport,
            bookmark,
            userData,
            pivotTable,
            is_slim_mode
        ) => {
            const {
                dimensions = [],
                metrics = [],
                discrepancyMetrics: discrepancy_metrics = [],
                cohortMetrics: cohort_metrics = [],
                periods: cohort_periods = [],
                timeBreakdowns = [],
                sourceAttributionType = [],
                withDruidAppend = [],
                druidAggregatedDataSourcesMode = [],
                crossDeviceDemoCohortType = [],
                crossDeviceCohortType = [],
                skanConversionDimensions: skan_conversion_dimensions = [],
                skan_redownloads_dimensions = [],
                skan_validated_dimensions = [],
                confidenceIntervalFlag = [],
                goalsMetrics: goals_metrics = [],
                goalsForecastMetrics: goals_forecast_metrics = [],
                customEvents = [],
                custom_dimensions = [],
                governanceDimensions = [],
                fileCombinerDimensions: file_combiner_dimensions = [],
                granularityLevels: granularity_levels = [],
                enrichmentDimensions: enrichment_dimensions = [],
                sourceDimensions: source_dimensions = [],
                metadataDimensions: metadata_dimensions = [],
                socialEngagementMetrics = [],
                xorgMetrics = [],
                xorgCohortMetrics = [],
                appMetrics = [],
                webMetrics = [],
                utmParamsFields = [],
                crossDeviceMetrics = [],
                skanModeledMetrics = [],
                skanModeledCohortMetrics: skan_modeled_cohort_metrics = [],
                skanModeledCohortPeriods: skan_modeled_cohort_periods = [],
                unifiedCohortPeriods: unified_cohort_periods = [],
                conversionEvents = [],
                skanModeledCustomEvents = [],
                adMonDAUMetrics = [],
                skanDateDimensionName,
                skanCustomEvents = [],
                dataTypeFields = [],
                metricGroupFields = [],
                cohortMetricGroupFields = [],
                technicalSpecsDimensions: technical_specs_dimensions = [],
                creativeMetadataDimensions: creative_metadata_dimensions = [],
            } = is_slim_mode ? visibleSelectedFields : selectedFields;
            let { start_date = '', end_date = '', start_date_2 = '', end_date_2 = '' } = dates;

            const filteredRows = getGridFilteredData(reportType);
            const time_breakdown = timeBreakdowns;
            const confidence_interval_flag = reportConfig.confidenceIntervalFlag || confidenceIntervalFlag;
            const skanValidatedDimensions = reportConfig.skanValidatedDimensions || skan_validated_dimensions;
            const source_attribution_type = reportConfig.sourceAttributionType || sourceAttributionType;
            const with_append_tables = reportConfig.withDruidAppend || withDruidAppend;
            const druid_aggregated_data_sources_mode =
                reportConfig.druidAggregatedDataSourcesMode || druidAggregatedDataSourcesMode;
            const cross_device_cohort_type = reportType === reportTypes.crossDevice ? crossDeviceCohortType : [];
            const cross_device_demo_cohort_type =
                reportType === reportTypes.crossDevice ? crossDeviceDemoCohortType : [];
            const is_goals = selectedTab === 'goal';
            const goal =
                selectedGoal && goals && goals.length ? goals.find(cGoal => cGoal.name === selectedGoal) : null;

            if (is_goals && goal) {
                start_date = moment(goal.start_date).format('YYYY-MM-DD');
                end_date = moment(goal.end_date).format('YYYY-MM-DD');
            }

            const api_version = reportConfig.isCreativeExplore ? API_VERSIONS.VERSION_2 : API_VERSIONS.VERSION_1;

            const request = {
                api_version,
                compare: selectedMode === COMPARE_MODE,
                is_goals,
                dimensions: dimensions
                    .concat(custom_dimensions)
                    .concat(skan_conversion_dimensions)
                    .concat(governanceDimensions)
                    .concat(utmParamsFields),
                metrics: metrics
                    .concat(xorgMetrics)
                    .concat(appMetrics)
                    .concat(webMetrics)
                    .concat(skanModeledMetrics)
                    .concat(crossDeviceMetrics)
                    .concat(conversionEvents)
                    .concat(adMonDAUMetrics)
                    .concat(socialEngagementMetrics),
                discrepancy_metrics,
                file_combiner_dimensions,
                granularity_levels,
                enrichment_dimensions,
                source_dimensions,
                metadata_dimensions,
                skan_conversion_dimensions,
                technical_specs_dimensions,
                creative_metadata_dimensions,
                cohort_metrics: cohort_metrics
                    .concat(customEvents)
                    .concat(xorgCohortMetrics)
                    .concat(skanCustomEvents),
                skan_modeled_cohort_metrics: reportConfig.skanModeledCohortMetrics || skan_modeled_cohort_metrics,
                modeled_skan_custom_events: skanModeledCustomEvents,
                data_type_fields: dataTypeFields,
                metric_group_fields: metricGroupFields,
                cohort_metric_group_fields: cohortMetricGroupFields,
                cohort_periods: reportConfig.cohortPeriods || cohort_periods,
                skan_modeled_cohort_periods: reportConfig.skanModeledCohortPeriods || skan_modeled_cohort_periods,
                unified_cohort_periods: reportConfig.unifiedCohortPeriods || unified_cohort_periods,
                goals_metrics,
                goals_forecast_metrics,
                filters: [...filters.filter(x => x.values.length > 0)],
                time_breakdown,
                skan_date_dimension_name: skanDateDimensionName?.length
                    ? skanDateDimensionName[0]
                    : reportConfig.skanDateDimensionName,
                source_attribution_type,
                with_append_tables,
                druid_aggregated_data_sources_mode,
                cross_device_cohort_type,
                cross_device_demo_cohort_type,
                start_date,
                start_date_2,
                end_date,
                end_date_2,
                goal,
                display_unenriched: unenriched,
                display_admon_alignment: admonAlignment,
                skan_redownloads_dimensions,
                skan_validated_dimensions: skanValidatedDimensions,
                confidence_interval_flag,
                show_redownloads_dimension_in_report: showRedownloadsDimensionInReport,
                show_validated_dimension_in_report: showValidatedDimensionInReport,
                bookmark_id: bookmark.bookmark_id,
                bookmark_creator: bookmark.bookmark_creator,
                updatedInstanceId: bookmark.updatedInstanceId,
                is_fraud: !!reportConfig.isFraud,
                is_skan_summary: !!reportConfig.isSkanSummary,
                is_unified_report: !!reportConfig.isUnifiedReport,
                is_creative_explore: !!reportConfig.isCreativeExplore,
                is_creative_reporting_query: !!reportConfig.isCreativeExplore,
                is_skan: !!reportConfig.isSkan,
                is_admon_report: !!reportConfig.is_admon_report,
                pivot_table: pivotTable,
                query_type: queryType,
                is_slim_mode,
            };

            // this is a temporary fix to remove this problematic dimension from existing queries. will be removed soon. let me know if you still see it :)
            request.dimensions = request.dimensions.filter(dim => {
                return dim !== PUBLISHER_ESTIMATED_INDICATION;
            });

            if (reportConfig.requireApiKey) {
                request.api_key = userData.api_key;
            }

            if (queryType === 'charts') {
                _addChartData(request, reportData, fieldsFilters, chart, filteredRows);
            }
            return request;
        }
    );
};

const getIsClearSelectionOn = reportType => {
    return createSelector([getConfigForType(reportType).selectors.isClearSelectionOn], isClearSelectionOn => {
        return isClearSelectionOn;
    });
};

const getReportDataForType = reportType => {
    return createSelector([getConfigForType(reportType).selectors.reportData], reportData => {
        return {
            reportData,
        };
    });
};

const getAnonymousList = reportType => {
    return createSelector([getConfigForType(reportType).selectors.anonymousList], anonymousList => {
        return anonymousList;
    });
};

const getAnonymousId = reportType => {
    return createSelector([getConfigForType(reportType).selectors.anonymousId], anonymousId => {
        return anonymousId;
    });
};

const getTableConfig = reportType => {
    return createSelector([getConfigForType(reportType).selectors.table], table => {
        return table;
    });
};

const getTotalsStatus = reportType =>
    createSelector([getTableConfig(reportType)], tableConf => tableConf.filteredTotal.status);

const getBookmark = reportType => {
    return createSelector([getConfigForType(reportType).selectors.bookmark], bookmark => {
        return bookmark;
    });
};

const getQueryRunning = reportType => {
    return createSelector([getConfigForType(reportType).selectors.queryRunning], isRunning => {
        return isRunning;
    });
};

const getGoalsDropdown = reportType => {
    return createSelector(
        [getConfigForType(reportType).selectors.selectedGoal, getFieldsForType('goals')],
        (selectedGoal, goals) => {
            let items = [];
            let selected = {
                name: 'default',
                display_name: 'STATIC.PAGES.GOALS.NO_GOALS_TEXT',
            };
            let disabled = true;
            if (goals && goals.length) {
                items = goals.map(goal => ({
                    name: goal.name,
                    display_name: `
                        <span style="display: flex; pointer-events: none;">
                            <span style="padding-right: 5px;">${goal.name} |</span>
                            <span style="margin-left: auto;">${goal.start_date} - ${goal.end_date}</span>
                        </span>
                `,
                }));
                selected = selectedGoal
                    ? items.find(item => item.name === selectedGoal)
                    : {
                          name: 'default2',
                          display_name: 'STATIC.PLACEHOLDERS.SELECT_GOAL',
                      };
                disabled = false;
            }
            return {
                items,
                selected,
                disabled,
            };
        }
    );
};

const getActiveBookmarkName = reportType => {
    return createSelector([getBookmark(reportType), getFieldsForType('bookmarks')], (bookmark = {}, bookmarks = []) => {
        const bookmarkData = bookmarks.find(({ uuid }) => bookmark.bookmark_id === uuid) || {};

        return bookmarkData.name;
    });
};

const getShowPopupDialog = (state, reportType) => {
    return createSelector([], () => {
        return state[reportType].showPopupDialog;
    });
};

export const getReportsFilters = (state, reportType) => {
    return createSelector([], () => {
        return state[reportType].filters;
    });
};

const getPopupDialogParams = (state, reportType) => {
    return createSelector([], () => {
        return state[reportType].popupDialogParams;
    });
};
const getIsEditShelfOpen = (state, reportType) => {
    return createSelector([], () => {
        return state[reportType].isEditShelfOpen;
    });
};

const getBookmarkPopoverData = reportType => {
    return createSelector(
        [
            getBookmark(reportType),
            getReportDates(reportType),
            getConfigForType(reportType).selectors.mode,
            getFieldsForType('bookmarks'),
        ],
        (bookmark, reportDates, mode, bookmarks) => {
            const {
                start_date: startDate,
                end_date: endDate,
                start_date_2: startDate2,
                end_date_2: endDate2,
            } = reportDates;
            let bookmarkData = null;
            if (bookmarks && bookmarks.length && bookmark && bookmark.bookmark_id) {
                bookmarkData = bookmarks.find(bm => bm.uuid === bookmark.bookmark_id);
            }
            if (!bookmarkData && bookmark && bookmark.bookmark) {
                bookmarkData = bookmark.bookmark;
            }
            return {
                bookmarkId: bookmark.bookmark_id,
                error: bookmark.error,
                bookmarkData,
                endDate,
                startDate,
                endDate2,
                startDate2,
                compareMode: mode === COMPARE_MODE,
                updatedInstanceId: bookmark.updatedInstanceId,
            };
        }
    );
};

// This selector retrieves filters that will match only the results currently queried (i.e., won't take into consideration
// the table filters, but the filters given by the query editor). Used mainly by the anonymous report, so there won't be
// any entries that will be added posteriori
const getFrozenFilters = reportType => {
    function filterValuesFromResults(filter, results) {
        return filter.values.filter(({ display_name, name }) => {
            const resultsFilterName = NETWORKS_SPECIAL_ORIGINAL_DISPLAY_NAME_MAPPING[name] || display_name;
            return results.map(result => result[filter.name]).includes(resultsFilterName);
        });
    }

    return createSelector([getConfigForType(reportType).selectors.reportData, getFilters], (reportData, filters) => {
        const { metadata, results } = reportData;
        const refFilters = filters.filter(f => Object.keys(metadata).includes(f.name));
        return refFilters.map(f => ({
            ...f,
            values: filterValuesFromResults(f, results),
        }));
    });
};

const getReportSpecificProp = (reportType, propName) => {
    return createSelector([getConfigForType(reportType).selectors[propName]], val => val);
};
const getShouldDrilldown = reportType => getReportSpecificProp(reportType, 'shouldDrilldown');
const getChartConfig = reportType => getReportSpecificProp(reportType, 'chart');
const getPostProcessFiltersUI = reportType => getReportSpecificProp(reportType, 'postProcessFiltersUI');

const reportDataSelectors = {};

// create the main report selectors, per type
reportsConfig.forEach(({ type, dataSelector, selectors }) => {
    const keys = Object.keys(dataSelector);
    reportDataSelectors[type] = createSelector(
        keys.map(selectorName => selectors[selectorName]),
        (...args) => {
            const ret = {};
            for (let i = 0; i < keys.length; i++) {
                ret[dataSelector[keys[i]]] = args[i];
            }
            return ret;
        }
    );
});

const getFieldGroupsToHide = () => {
    return createSelector([getUserData], ({ event_slot_analysis_types: eventSlotAnalysisTypesForOrg = [] }) => {
        return {
            conversionEvents: !showConversionEvents(eventSlotAnalysisTypesForOrg),
            customEvents: !showCohortEvents(eventSlotAnalysisTypesForOrg),
        };
    });
};

const getCustomFieldGroupLabels = () => {
    return createSelector([getUserData], ({ event_slot_analysis_types: eventSlotAnalysisTypesForOrg = [] }) => {
        const customFieldGroupLabels = {};

        if (showConversionEvents(eventSlotAnalysisTypesForOrg)) {
            customFieldGroupLabels.customEvents = 'STATIC.COHORT_EVENTS';
        }

        return customFieldGroupLabels;
    });
};

export {
    getCalcFilterForType,
    getAvailableFiltersForType,
    getWarningForType,
    shouldShowDownloadReport,
    getQueryDataForType,
    getReportDataForType,
    getAnonymousId,
    getAnonymousList,
    getTableConfig,
    getReportDates,
    getBookmark,
    getGoalsDropdown,
    reportDataSelectors,
    getBookmarkPopoverData,
    getTableFormattedData,
    getQueryRunning,
    getConfigForType,
    getFrozenFilters,
    getShouldDrilldown,
    getChartConfig,
    getTotalsStatus,
    getAvailableDimensionsForDrilldown,
    getPostProcessFiltersUI,
    getIsClearSelectionOn,
    getCustomFieldGroupLabels,
    getFieldGroupsToHide,
    getActiveBookmarkName,
    getShowPopupDialog,
    getPopupDialogParams,
    getShelfShown,
    getIsEditShelfOpen,
};
